import React from 'react'
import "./infrastruct.css";
import Slider from 'react-slick';

import Gallery1 from "../../../assets/aboutpg/2.JPG";
import Gallery2 from "../../../assets/aboutpg/3.JPG";
import Gallery3 from "../../../assets/aboutpg/4.JPG";
import Gallery4 from "../../../assets/aboutpg/5.JPG";
import Gallery5 from "../../../assets/aboutpg/6.JPG";
import Gallery6 from "../../../assets/aboutpg/7.JPG";
// import Gallery7 from "../../../assets/aboutpg/1.JPG";


export const  Infrastruct = (props) => {
   
    const products = [
        { id: 1, src: Gallery1},
        { id: 2, src: Gallery2},
        { id: 3, src: Gallery3},
        { id: 4, src: Gallery4},
        { id: 5, src: Gallery5},
        { id: 6, src: Gallery6},        
    ];

    const settings = {
        dots: false,  // Shows navigation dots       
        slidesToShow: 4,  // Show 4 slides on larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        centerPadding: "60px",
        rtl:props.rtlValue,
        responsive: [
            {
                breakpoint: 1024,  // Tablet & laptop
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,                    
                }
            },
            {
                breakpoint: 768,  // Small tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,  // Mobile
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
  return(
   
    <div className="slider-container">
        <Slider {...settings}>
                {products.map((product) => (
                    <div key={product.id} className="product-slide">
                        <img src={product.src} alt={product.name} className="product-image" />                        
                    </div>
                ))}
        </Slider>
    </div>           
   )
  };

export default Infrastruct;