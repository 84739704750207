import React from 'react';
import "./product.css";
import SharedButton from '../shared/button/button';

//ro chemicals
import Alkalinitybuilders from "../../assets/productpg/RO/Alkalinitybuilders.jpeg";
import Biocidesandbiodispersants from "../../assets/productpg/RO/Biocidesandbiodispersants.jpeg";
import Corrosioninhibitors from "../../assets/productpg/RO/Corrosioninhibitors.jpeg";
import Scaleinhibitorsanddispersants from "../../assets/productpg/RO/Scaleinhibitorsanddispersants.jpeg";
import Dewateringacids from "../../assets/productpg/RO/Dewateringacids.jpeg";
import Defoamerschemical from "../../assets/productpg/RO/De-foamers-chemical.jpeg";
import DOPE from "../../assets/productpg/RO/DOPE.jpeg";
import Antiscalantforcentrifugeanddecanters from "../../assets/productpg/RO/Antiscalantforcentrifugeanddecanters.jpeg";
import CF from "../../assets/productpg/RO/CF.jpeg";
import BioCides from "../../assets/productpg/RO/Bio-Cides.jpeg";
import ETP from "../../assets/productpg/RO/ETP.jpeg";
import pHCorrections from "../../assets/productpg/RO/pHCorrections.jpeg";
import RODeChlorinators from "../../assets/productpg/RO/RODeChlorinators.jpeg";
import ROCorrosionInhibitors from "../../assets/productpg/RO/ROCorrosionInhibitors.jpeg";
import ROFlocculants from "../../assets/productpg/RO/ROFlocculants.jpeg";
import ROMembrabe from "../../assets/productpg/RO/ROMembrabe.jpeg";
import ROMembraneCleaners from "../../assets/productpg/RO/ROMembraneCleaners.jpeg";
import ROMembraneBiocidesDisinfectants from "../../assets/productpg/RO/ROMembraneBiocidesDisinfectants.jpeg";

//comercial product
import CitricAcid99 from "../../assets/productpg/CitricAcid-99.jpeg";
import HydroChloricAcid33 from "../../assets/productpg/HydroChloricAcid-33.jpeg";
import HydrozenPeroxide50 from "../../assets/productpg/HydrozenPeroxide-50.jpeg";
import NitricAcid72 from "../../assets/productpg/NitricAcid-72.jpeg";
import PhosphoricAcid85fg from "../../assets/productpg/PhosphoricAcid-85-fg.jpeg";
import Causticsoda from "../../assets/productpg/causticsoda.jpeg";
import CausticSodalye from "../../assets/productpg/CausticSodalye.jpeg";
import IsoProphylAlcohol from "../../assets/productpg/IsoProphylAlcohol.jpeg";
import PhosphoricAcid85 from "../../assets/productpg/PhosphoricAcid-85.jpeg";
import SodiumMetaBiSulphate from "../../assets/productpg/SodiumMetaBiSulphate.jpeg";
import SodiumSilicateLiquid from "../../assets/productpg/SodiumSilicate-Liquid.jpeg";
import SulphamicAcidCommercialGrade from "../../assets/productpg/SulphamicAcidCommercialGrade.jpeg";
import SulphuricAcid98 from "../../assets/productpg/SulphuricAcid-98.jpeg";
import Sodiumhydroxide from "../../assets/productpg/Sodiumhydroxide.jpeg";
import PotassiumSilicateLiquid from "../../assets/productpg/PotassiumSilicateLiquid.jpeg";

import polyposh from "../../assets/productpg/polyposh.jpeg";
import oxalic from "../../assets/productpg/oxalic.jpeg";
import hydratedlime from "../../assets/productpg/hydratedlime.jpeg";
import hypo6 from "../../assets/productpg/hypo6.jpeg";
import hypo12 from "../../assets/productpg/hypo12.jpeg";
import tccacid from "../../assets/productpg/tccacid.jpeg";
import hydrofluric40 from "../../assets/productpg/hydrofluric40.jpeg";
import hydrofluric60 from "../../assets/productpg/hydrofluric60.jpeg";
import hydrofluric70 from "../../assets/productpg/hydrofluric70.jpeg";
import nadcc from "../../assets/productpg/nadcc.jpeg";
import sodaash from "../../assets/productpg/sodaash.jpeg";


const  Product= (props) => {
    const handleClick = () => {
        window.location.href="/about";
    };
    const roproducts = [
        { id: 1, src: Alkalinitybuilders, name: 'Alkalinity builders' },
        { id: 2, src: Biocidesandbiodispersants, name: 'Bio-cides and bio-dispersants' },
        { id: 3, src: Corrosioninhibitors, name: 'Corrosion inhibitors' },
        { id: 4, src: Scaleinhibitorsanddispersants, name: 'Scale inhibitors and dispersants' },
        { id: 5, src: Dewateringacids, name: 'De-watering acids' },
        { id: 6, src: Defoamerschemical, name: 'De-foamers-chemical' },
        { id: 7, src: DOPE, name: 'De-oiling Polyelectrolyte (DOPE)' },
        { id: 8, src: Antiscalantforcentrifugeanddecanters, name: 'Anti-scalant for centrifuge and decanters' },
        { id: 9, src: CF, name: 'Coagulants and flocculants' },
        { id: 10, src: BioCides, name: 'Bio-Cides' },
        { id: 11, src: ETP, name: 'ETP Chemicals' },
        { id: 12, src: pHCorrections, name: 'pH Corrections' },
        { id: 13, src: RODeChlorinators, name: 'RO De-Chlorinators' },
        { id: 14, src: ROCorrosionInhibitors, name: 'RO Corrosion Inhibitors' },
        { id: 15, src: ROFlocculants, name: 'RO Flocculants' },
        { id: 16, src: ROMembrabe, name: 'RO Membrabe' },
        { id: 17, src: ROMembraneCleaners, name: 'RO Membrane Cleaners' },
        { id: 18, src: ROMembraneBiocidesDisinfectants, name: 'RO Membrane Biocides Disinfectants' },
    ];

    const cproducts = [
        { id: 1, src: CitricAcid99, name: 'Citric Acid 99%',grade:'Commercial/Industrial',packing:"25Kg" },
        { id: 2, src: Causticsoda, name: 'Caustic Soda-Flakes',grade:'Food/Industrial',packing:"50Kg" },
        { id: 3, src: CausticSodalye, name: 'Caustic Soda-Lye-48%',grade:'Food/Industrial',packing:"50Kg" },
        { id: 4, src: hydratedlime, name: 'Hydrated lime 90%',grade:'Food Grade',packing:"50Ltrs" },
        { id: 5, src: HydroChloricAcid33, name: 'Hydro Chloric Acid 33%',grade:'Commercial/Industrial',packing:"40Kg" },
        { id: 6, src: HydrozenPeroxide50, name: 'Hydrozen Peroxide 50%',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 7, src: hydrofluric40, name: 'Hydrofluoric acid 40%',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 8, src: hydrofluric60, name: 'Hydrofluoric acid 60%',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 9, src: hydrofluric70, name: 'Hydrofluoric acid 70%',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 10, src: hypo6, name: 'Hypochlorite  6 %',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 11, src: hypo12, name: 'Hypochlorite  12%',grade:'Commercial/Industrial',packing:"63Kg" },  
        { id: 12, src: IsoProphylAlcohol, name: 'Iso Prophyl Alcohol',grade:'Commercial/Industrial',packing:"50Ltrs" },
        { id: 13, src: nadcc, name: 'Sodium dichloroisocyanurate (NaDCC)',grade:'Commercial/Industrial',packing:"25Kg" },
        { id: 14, src: NitricAcid72, name: 'Nitric Acid 72%',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 15, src: oxalic, name: 'oxalic acid- 99%',grade:'Commercial/Industrial',packing:"50Ltrs" },
        { id: 16, src: PhosphoricAcid85fg, name: 'Phosphoric Acid - 85%',grade:'Commercial/Industrial',packing:"50Ltrs" },
        { id: 17, src: PhosphoricAcid85, name: 'Phosphoric Acid - 85%',grade:'Food Grade',packing:"50Ltrs" },
        { id: 18, src: PotassiumSilicateLiquid, name: 'Potassium Silicate- Liquid',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 19, src: polyposh, name: 'poly phosphoric acid - 115%',grade:'Commercial/Industrial',packing:"50Ltrs" },
        { id: 20, src: SulphuricAcid98, name: 'Sulphuric Acid 98%',grade:'Commercial/Industrial',packing:"63Kg" },    
        { id: 21, src: SodiumMetaBiSulphate, name: 'Sodium Meta Bi-Sulphate',grade:'Commercial/Industrial',packing:"25Kg" },
        { id: 22, src: SodiumSilicateLiquid, name: 'Sodium Silicate-Liquid',grade:'Commercial/Industrial',packing:"60Kg" },
        { id: 23, src: SulphamicAcidCommercialGrade, name: 'Sulphamic Acid',grade:'Commercial/Industrial',packing:"50Kg" },
        { id: 24, src: Sodiumhydroxide, name: 'Sodium Hydroxide-Pure-Pellets-Pure',grade:'Commercial/Industrial',packing:"50Kg" },        
        { id: 25, src: SodiumSilicateLiquid, name: 'Trichloroisocyanuric acid',grade:'Commercial/Industrial',packing:"60Kg" },
        { id: 26, src: sodaash, name: 'Soda ash',grade:'Commercial/Industrial',packing:"50Kg" },        
        { id: 27, src: tccacid, name: 'trisodium phosphate',grade:'Commercial/Industrial',packing:"50Kg" }, 
    ];

  return(
    <div className='container'>
        <div className='product-intro-container'>
            <div></div>
            <div>
                <h2>Our Products</h2>
            </div>
            <div>
                <p>We know how large objects will act, but things on a <br></br>
                small scale just do not act that way.</p>
            </div>
            <div>
                <SharedButton
                    content="Learn More"
                    onClick={handleClick}
                    btntype={"filled"}
                />
            </div>
        </div>
        <br></br>
        {/* for product */}
        <div className='home-carosel-container'>
        <ul className="nav nav-tabs home-mob-ul" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="navheadings nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                    Commercial Chemicals
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button className=" navheadings nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> RO, ETP, STP Chemicals</button>
            </li>               
        </ul>
        <br></br>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className='subproduct-container'>
                    {cproducts.map((product) => (
                        <div key={product.id} className="pro-product-slide">
                            <img src={product.src} alt={product.name} className="pro-product-image" />
                            <h6>{product.name}</h6>
                            <p><span>Grade</span>:<span>{product.grade}</span></p>
                            <p><span>Packing</span>:<span>{product.packing}</span></p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className='subproduct-container'>
                    {roproducts.map((product) => (
                        <div key={product.id} className="pro-product-slide">
                            <img src={product.src} alt={product.name} className="pro-product-image" />
                            <h6>{product.name}</h6>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      </div>
      <br></br>
    </div>
   )
  }

export default Product;