import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './productCarousel.css'; // You can customize additional styles here

import CitricAcid99 from "../../assets/productpg/CitricAcid-99.jpeg";
import HydroChloricAcid33 from "../../assets/productpg/HydroChloricAcid-33.jpeg";
import HydrozenPeroxide50 from "../../assets/productpg/HydrozenPeroxide-50.jpeg";
import NitricAcid72 from "../../assets/productpg/NitricAcid-72.jpeg";
import PhosphoricAcid85fg from "../../assets/productpg/PhosphoricAcid-85-fg.jpeg";
import Causticsoda from "../../assets/productpg/causticsoda.jpeg";
import CausticSodalye from "../../assets/productpg/CausticSodalye.jpeg";
import IsoProphylAlcohol from "../../assets/productpg/IsoProphylAlcohol.jpeg";
import PhosphoricAcid85 from "../../assets/productpg/PhosphoricAcid-85.jpeg";
import SodiumMetaBiSulphate from "../../assets/productpg/SodiumMetaBiSulphate.jpeg";
import SodiumSilicateLiquid from "../../assets/productpg/SodiumSilicate-Liquid.jpeg";
import SulphamicAcidCommercialGrade from "../../assets/productpg/SulphamicAcidCommercialGrade.jpeg";
import SulphuricAcid98 from "../../assets/productpg/SulphuricAcid-98.jpeg";
import Sodiumhydroxide from "../../assets/productpg/Sodiumhydroxide.jpeg";
import PotassiumSilicateLiquid from "../../assets/productpg/PotassiumSilicateLiquid.jpeg";

import polyposh from "../../assets/productpg/polyposh.jpeg";
import oxalic from "../../assets/productpg/oxalic.jpeg";
import hydratedlime from "../../assets/productpg/hydratedlime.jpeg";
import hypo6 from "../../assets/productpg/hypo6.jpeg";
import hypo12 from "../../assets/productpg/hypo12.jpeg";
import tccacid from "../../assets/productpg/tccacid.jpeg";
import hydrofluric40 from "../../assets/productpg/hydrofluric40.jpeg";
import hydrofluric60 from "../../assets/productpg/hydrofluric60.jpeg";
import hydrofluric70 from "../../assets/productpg/hydrofluric70.jpeg";
import nadcc from "../../assets/productpg/nadcc.jpeg";
import sodaash from "../../assets/productpg/sodaash.jpeg";

const ProductCarousel = () => {
    const products = [
        { id: 1, src: CitricAcid99, name: 'Citric Acid 99%'},
        { id: 2, src: Causticsoda, name: 'Caustic Soda-Flakes'},
        { id: 3, src: CausticSodalye, name: 'Caustic Soda-Lye-48%'},
        { id: 4, src: hydratedlime, name: 'Hydrated lime 90%',grade:'Food Grade',packing:"50Ltrs" },
        { id: 5, src: HydroChloricAcid33, name: 'Hydro Chloric Acid 33%'},
        { id: 6, src: HydrozenPeroxide50, name: 'Hydrozen Peroxide 50%' },
        { id: 7, src: hydrofluric40, name: 'Hydrofluoric acid 40%'},
        { id: 8, src: hydrofluric60, name: 'Hydrofluoric acid 60%' },
        { id: 9, src: hydrofluric70, name: 'Hydrofluoric acid 70%' },
        { id: 10, src: hypo6, name: 'Hypochlorite  6 %' },
        { id: 11, src: hypo12, name: 'Hypochlorite  12%'},  
        { id: 12, src: IsoProphylAlcohol, name: 'Iso Prophyl Alcohol' },
        { id: 13, src: nadcc, name: 'Sodium dichloroisocyanurate (NaDCC)' },
        { id: 14, src: NitricAcid72, name: 'Nitric Acid 72%'},
        { id: 15, src: oxalic, name: 'oxalic acid- 99%'},
        { id: 16, src: PhosphoricAcid85fg, name: 'Phosphoric Acid - 85%' },
        { id: 17, src: PhosphoricAcid85, name: 'Phosphoric Acid - 85%' },
        { id: 18, src: PotassiumSilicateLiquid, name: 'Potassium Silicate- Liquid' },
        { id: 19, src: polyposh, name: 'poly phosphoric acid - 115%'},
        { id: 20, src: SulphuricAcid98, name: 'Sulphuric Acid 98%' },    
        { id: 21, src: SodiumMetaBiSulphate, name: 'Sodium Meta Bi-Sulphate' },
        { id: 22, src: SodiumSilicateLiquid, name: 'Sodium Silicate-Liquid' },
        { id: 23, src: SulphamicAcidCommercialGrade, name: 'Sulphamic Acid' },
        { id: 24, src: Sodiumhydroxide, name: 'Sodium Hydroxide-Pure-Pellets-Pure'},        
        { id: 25, src: SodiumSilicateLiquid, name: 'Trichloroisocyanuric acid' },
        { id: 26, src: sodaash, name: 'Soda ash'},        
        { id: 27, src: tccacid, name: 'trisodium phosphate' },
    ];

    const settings = {
        dots: false,  // Shows navigation dots
        infinite: true,
        speed: 500,
        slidesToShow: 4,  // Show 4 slides on larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,  // Tablet & laptop
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,  // Small tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,  // Mobile
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    return (
        <div className="carousel-container">
            <Slider 
            ref={slider => {
                sliderRef = slider;
              }}
            {...settings}>
                {products.map((product) => (
                    <div key={product.id} className="product-slide">
                        <img src={product.src} alt={product.name} className="product-image" />
                        <h5 className='pd-slider-text'>{product.name}</h5>
                    </div>
                ))}
            </Slider>
            <div style={{ textAlign: "center" }}>
                <button className="button-coursel" onClick={previous}>
                    &lt;
                </button>
                <button className="button-coursel" onClick={next}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default ProductCarousel;
