import React from 'react';
import "./button.css";

const SharedButton = ({ btntype, content, onClick }) => {
    

    return (
        btntype && btntype === "outline" ? 
        <button className="sharedbutton sharedoutlinebtn" onClick={()=>onClick(content)}>
            {content}
        </button>
        :
        <button className="sharedbutton sharedfilledbtn" onClick={()=>onClick(content)}>
            {content}
        </button>
    );
};

export default SharedButton;
