import React from 'react'
import "../../home/home.css";
import AboutMan from "../../../assets/homepg/man-2.png";
import SharedButton from '../button/button';

export const  Welcome = (props) => {
    const handleClick = (val) => {
        window.location.href="/about";
    };
  return(
    <div>
        <div className='home-aboutus-container'>
            <div className='home-introimg-right'>
                <img src={AboutMan} alt="man image"/>
            </div>
            <div className='home-about-right'>
                <div></div>
                <div>
                    <h1>
                        Welcome to
                    </h1>
                    <h1>
                    Raju Chemical Industries
                    </h1>
                </div>
                <div>
                    <p className='home-welcome-para'>
                    Raju Chemical Industries, established in 1980, is a prominent manufacturer and 
                    distributor of industrial chemicals, Lab Chemicals and process chemicals , throughout South India.
                    Founded by Mr R. Damodara Raju, the company is headquartered in Chennai, India. 
                    Renowned for the purity and reactivity of its chemicals, Raju Chemical Industries 
                    ensures that all products meet high standards of quality.
                    </p>
                </div>
                <div className='aboutus-btn-container'>
                    <SharedButton
                        content="Learn More"
                        onClick={(val)=>handleClick(val)}
                        btntype={"filled"}
                    />
                </div>
            </div>
        </div>
    </div>
   )
  }

export default Welcome;