import React from 'react'
import "./home.css";
import Home from './home';
import SharedButton from '../shared/button/button';
import Vision from '../shared/comp/vision';
import TeamBanner from '../shared/comp/team';
import Suppliers from '../shared/suppliers/supplier';

export const HomeContainer = (props) => {  
    const handleClick = (val) => {
        if(val === "Contact Us"){
            window.location.href="/contact";
        }        
    };
  return(
    <div>
        <div class="container">
            <Home/>
        </div>
        <div className='home-banner1-container'>
            <div>
                <h1><b>LOOKING FOR A QUALITY</b> <br></br> <b> CHEMICALS ?</b></h1>
            </div>
            <div className='banner1-btn-container'>
                <SharedButton
                    content="Contact Us"
                    onClick={handleClick}
                    btntype={"filled"}
                />
            </div>
        </div>
        <br></br>
        <div class="container">
            <Vision/>
        </div>
        {/* team container */}
        <br></br>
            <TeamBanner/>
        <br></br>
        <div className='home-supplier'>
            <h1>Our Collaborator Brands</h1>
            <Suppliers/>
        </div>
        <br></br>
        
    </div>
   )
  }

export default HomeContainer;