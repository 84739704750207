import React, { useState } from "react";
import "./enquiryform.css";
import SharedButton from "../shared/button/button";

const CustomForm = () => {
  const [show,setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    mobile: "",
    products: {
      commercialChemicals: true,
      roChemicals: false,
      etpChemicals: false,
      stpChemicals: false,
    },
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData;

    if (type === "checkbox") {
      updatedFormData = {
        ...formData,
        products: {
          ...formData.products,
          [name]: checked,
        },
      };
    } else {
      updatedFormData = {
        ...formData,
        [name]: value,
      };
    }

    setFormData(updatedFormData);
    validateForm(updatedFormData); // Pass the updated form data for validation

    // console.log("Formdata values", updatedFormData);
  };

  const validateForm = (formData) => {
    let formErrors = {};
    
    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.companyName) formErrors.companyName = "Company name is required";
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!formData.mobile) {
      formErrors.mobile = "Mobile number is required";
    } else if (!mobileRegex.test(formData.mobile)) {
      formErrors.mobile = "Invalid mobile number, should be 10 digits";
    }    

    if (!formData.message) formErrors.message = "Message is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleClick = () => {   
    if (validateForm(formData)) {    
     // Combine product checkboxes into a single comma-separated string
     const selectedProducts = [];
     if (formData.products.commercialChemicals){} selectedProducts.push("Commercial Chemicals");
     if (formData.products.roChemicals) selectedProducts.push("RO Chemicals");
     if (formData.products.etpChemicals) selectedProducts.push("ETP Chemicals");
     if (formData.products.stpChemicals) selectedProducts.push("STP Chemicals");
    
    // console.log("seleceted prd ",selectedProducts);   
    // Custom function to encode the form data properly, replacing space with '+'
    const encodeForGoogleForms = (str) => {
        return encodeURIComponent(str).replace(/%20/g, "+");
    };

    // Mapping over productArray and creating a query string for the product entries
    const productQueryString = selectedProducts
        .map((product) => `&entry.302042350=${encodeForGoogleForms(product)}`)
        .join("");

    // Construct the full URL with other form data and the dynamically generated product fields
    const SENDURL = `https://docs.google.com/forms/d/e/1FAIpQLSceb3QZMHo8stKOKdlDt2tzHjZcmRU_3cnhqRuOKP0dVzi-5Q/formResponse?entry.1271888403=${encodeForGoogleForms(
        formData.name
    )}&entry.1258156633=${encodeForGoogleForms(
        formData.companyName
    )}&entry.531190103=${formData.email}&entry.557165464=${encodeForGoogleForms(formData.mobile)}${productQueryString}&entry.1589548894=${encodeForGoogleForms(formData.message)}`;

    
    fetch(SENDURL, {
        method: "POST",
        mode: "no-cors", // Important to avoid CORS issues
      })
    .then(() => {
        // console.log("Form successfully submitted!");
        setShow(true);
        setTimeout(()=>{
          window.location.reload();
        }, 8000);
    })
    .catch((error) => {
        console.error("Error submitting form:", error);
    });   
  }  
  };

  return (
    <>
    {show ? (
      <div className="form-sucess-container">
          <div className="form-sucess-subcont">         
            <h1>Thank you for showing your interset !...</h1>
            <h3>Our business team will reach you soon</h3>
            <iframe title="Success msg" src="https://lottie.host/embed/824875d2-c058-4644-9596-c824702dddc2/jRf5YJGYPd.json"></iframe>
          </div>
      </div>
      )
      :(
          <div className="forms-containers">
          <div className="forms-sub-container">       
            <div>
              <label>Name<span className="required-asterisk">*</span></label><br></br>
              <input
                className="forms-input"
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div>
              <label>Company Name<span className="required-asterisk">*</span></label><br></br>
              <input
                type="text"
                className="forms-input"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
              {errors.companyName && (
                <span className="error-message">{errors.companyName}</span>
              )}
            </div>
          </div>
          <div className="forms-sub-container">  
            <div>
              <label>Email<span className="required-asterisk">*</span></label><br></br>
              <input
                type="email"
                className="forms-input"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div>
              <label>Mobile Number<span className="required-asterisk">*</span></label><br></br>
              <input
                type="tel"
                name="mobile"
                className="forms-input"
                placeholder="Mobile Number"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              {errors.mobile && (
                <span className="error-message">{errors.mobile}</span>
              )}
            </div>
          </div>
          <div className="forms-sub-container">  
            <div>
              <label>Products<span className="required-asterisk">*</span></label>
              <div className="form-productgrid">
                <input
                  type="checkbox"
                  name="commercialChemicals"
                  checked={formData.products.commercialChemicals}
                  onChange={handleChange}
                />
              <span> Commercial Chemicals</span>
              </div>
              <div className="form-productgrid">
                <input
                  type="checkbox"
                  name="roChemicals"
                  checked={formData.products.roChemicals}
                  onChange={handleChange}
                />
                <span> RO Chemicals</span>
              </div>
              <div className="form-productgrid">
                <input
                  type="checkbox"
                  name="etpChemicals"
                  checked={formData.products.etpChemicals}
                  onChange={handleChange}
                />
                <span>ETP Chemicals</span>
              </div>
              <div className="form-productgrid">
                <input
                  type="checkbox"
                  name="stpChemicals"
                  checked={formData.products.stpChemicals}
                  onChange={handleChange}
                />
                <span>STP Chemicals</span>
              </div>
              {errors.products && (
                <span className="error-message">{errors.products}</span>
              )}
            </div>
            
            <div>
              <label>Message<span className="required-asterisk">*</span></label><br></br>
              <textarea
                name="message"
                className="forms-textarea"
                placeholder="Write your Message limited to 200 words"
                value={formData.message}
                onChange={handleChange}
                required
              />
              {errors.message && (
                <span className="error-message">{errors.message}</span>
              )}
            </div>  
          </div>
          <div className="forms-btn-container">  
            <SharedButton
              content="Submit Now"
              onClick={handleClick}
              btntype={"filled"}
            />
          </div>
          </div>
      )}
    </>
    
  );
};

export default CustomForm;
