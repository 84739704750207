import React from 'react'
import "../../home/home.css";
import SharedButton from '../button/button';

export const  TeamBanner = (props) => {
   

    const handleClick = (val) => {
        if(val === "Contact Us"){
            window.location.href="/contact";
        }        
    };
  return(
    <div>
       <div className='home-team-container'>
            <h1>Our Company Team</h1>
            <p>Each member of our team is a qualified professional,
            skilled in their respective fields.</p>
            <div>
            <SharedButton
                content="Contact Us"
                onClick={handleClick}
                btntype={"filled"}
            />
            </div>
        </div>
    </div>
   )
  }

export default TeamBanner;