import './App.css';
import { BrowserRouter,Routes, Route } from "react-router-dom";
import HomeContainer from './components/home/homeContainer';
import React, { useState,useEffect } from 'react';
import NavHeader from './components/navHeader/navheader';
import Page404 from './components/shared/404page/Page404';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import Product from './components/product/product';
import About from './components/about/about';
import SplashScreen from './components/shared/splashscreen/splash';


function App() {
  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    // Check if the splash screen has already been shown
    const splashScreenShown = localStorage.getItem('splashScreenShown');

    if (!splashScreenShown) {
      // If splash screen has not been shown, show it and set localStorage
      setShowSplash(true);
      const timeout = setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem('splashScreenShown', 'true'); // Mark splash as shown
      }, 2500); // Duration of splash screen

      return () => clearTimeout(timeout);
    }
  }, []);

  // If the splash screen is still to be shown, show the SplashScreen component
  if (showSplash) {
    return <SplashScreen />;
  }

  // Normal app rendering
  return (
    <BrowserRouter>
      <div>
        <div className="container">
          <NavHeader />
        </div>
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/product" exact element={<Product />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
