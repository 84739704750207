import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './productCarousel.css'; // You can customize additional styles here

import Alkalinitybuilders from "../../assets/productpg/RO/Alkalinitybuilders.jpeg";
import Biocidesandbiodispersants from "../../assets/productpg/RO/Biocidesandbiodispersants.jpeg";
import Corrosioninhibitors from "../../assets/productpg/RO/Corrosioninhibitors.jpeg";
import Scaleinhibitorsanddispersants from "../../assets/productpg/RO/Scaleinhibitorsanddispersants.jpeg";
import Dewateringacids from "../../assets/productpg/RO/Dewateringacids.jpeg";
import Defoamerschemical from "../../assets/productpg/RO/De-foamers-chemical.jpeg";
import DOPE from "../../assets/productpg/RO/DOPE.jpeg";
import Antiscalantforcentrifugeanddecanters from "../../assets/productpg/RO/Antiscalantforcentrifugeanddecanters.jpeg";
import CF from "../../assets/productpg/RO/CF.jpeg";
import BioCides from "../../assets/productpg/RO/Bio-Cides.jpeg";
import ETP from "../../assets/productpg/RO/ETP.jpeg";
import pHCorrections from "../../assets/productpg/RO/pHCorrections.jpeg";
import RODeChlorinators from "../../assets/productpg/RO/RODeChlorinators.jpeg";
import ROCorrosionInhibitors from "../../assets/productpg/RO/ROCorrosionInhibitors.jpeg";
import ROFlocculants from "../../assets/productpg/RO/ROFlocculants.jpeg";
import ROMembrabe from "../../assets/productpg/RO/ROMembrabe.jpeg";
import ROMembraneCleaners from "../../assets/productpg/RO/ROMembraneCleaners.jpeg";
import ROMembraneBiocidesDisinfectants from "../../assets/productpg/RO/ROMembraneBiocidesDisinfectants.jpeg";

const RoProductCarousel = () => {
    const products = [
        { id: 1, src: Alkalinitybuilders, name: 'Alkalinity builders' },
        { id: 2, src: Biocidesandbiodispersants, name: 'Bio-cides and bio-dispersants' },
        { id: 3, src: Corrosioninhibitors, name: 'Corrosion inhibitors' },
        { id: 4, src: Scaleinhibitorsanddispersants, name: 'Scale inhibitors and dispersants' },
        { id: 5, src: Dewateringacids, name: 'De-watering acids' },
        { id: 6, src: Defoamerschemical, name: 'De-foamers-chemical' },
        { id: 7, src: DOPE, name: 'De-oiling Polyelectrolyte (DOPE)' },
        { id: 8, src: Antiscalantforcentrifugeanddecanters, name: 'Anti-scalant for centrifuge and decanters' },
        { id: 9, src: CF, name: 'Coagulants and flocculants' },
        { id: 10, src: BioCides, name: 'Bio-Cides' },
        { id: 11, src: ETP, name: 'ETP Chemicals' },
        { id: 12, src: pHCorrections, name: 'pH Corrections' },
        { id: 13, src: RODeChlorinators, name: 'RO De-Chlorinators' },
        { id: 14, src: ROCorrosionInhibitors, name: 'RO Corrosion Inhibitors' },
        { id: 15, src: ROFlocculants, name: 'RO Flocculants' },
        { id: 16, src: ROMembrabe, name: 'RO Membrabe' },
        { id: 17, src: ROMembraneCleaners, name: 'RO Membrane Cleaners' },
        { id: 18, src: ROMembraneBiocidesDisinfectants, name: 'RO Membrane Biocides Disinfectants' },
    ];

    const settings = {
        dots: false,  // Shows navigation dots
        infinite: true,
        speed: 500,
        slidesToShow: 4,  // Show 4 slides on larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,  // Tablet & laptop
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,  // Small tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,  // Mobile
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    return (
        <div className="carousel-container">
            <Slider 
            ref={slider => {
                sliderRef = slider;
              }}
            {...settings}>
                {products.map((product) => (
                    <div key={product.id} className="product-slide">
                        <img src={product.src} alt={product.name} className="product-image" />
                        <h5>{product.name}</h5>
                    </div>
                ))}
            </Slider>
            <div style={{ textAlign: "center" }}>
                <button className="button-coursel" onClick={previous}>
                    &lt;
                </button>
                <button className="button-coursel" onClick={next}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default RoProductCarousel;
