import React,{useState} from 'react';
import "./footer.css";
import LogoFotter from "../../assets/common/whitelogo.png";
import Instagram from "../../assets/common/instagram.png";
import Whatsup from "../../assets/common/whatsup.png";
import Linkedin from "../../assets/common/linkdin.png";
import { Modal, Button } from 'react-bootstrap';

// Material-UI Icons
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';  // For Product
import InfoIcon from '@mui/icons-material/Info';  // About
import ContactMailIcon from '@mui/icons-material/ContactMail';  // Contact

export const Footer = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleimgClick = (val) => {
        if(val === "wp"){
                window.open("https://wa.me/message/3NLUQXK43GDLB1",'_blank');
        }else if(val === "ins"){

        }else if(val === "lk"){
                window.open("https://www.linkedin.com/in/raju-chemicals-industry/",'_blank');
        }
    }
  return(
    <div>
        <div  className='footer-container'>   
            <div className='footer-leftcontainer'>         
                <div className='footer-subcontainer-img'>
                    <img
                        src={LogoFotter}
                        alt='Raju Logo'
                    />
                </div>
                <div className='footer-subcontainer'>
                    <div className='footer-insubcontainer'>
                        <div>
                            <p>Service</p>
                            <div>Fast Delivery</div>
                            <div>Good Performance</div>
                            <div>Pure Service</div>
                            <div>Cost Effectiveness</div>
                        </div> 
                        <div>
                            <p>Links</p>
                            <div className='footer-links-atag'>
                                <div><a  className="nav-link" href="/"><HomeIcon/><span>Home</span></a></div>
                                <div><a  className="nav-link" href="/product"><ShoppingCartIcon/><span>Product</span></a></div>
                                <div><a   className="nav-link" href="/about"><InfoIcon/><span>About</span></a></div>
                                <div><a   className="nav-link" href="/contact"><ContactMailIcon/><span>Contact</span></a></div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-insubcontainer'>
                        <div>
                            <p>Address</p>
                            <div>                    
                                No. 40,Krishna Industrial Estate, 
                                <br></br>
                                Bala Murugan koil Street,
                                <br></br>
                                Vanagaram, Chennai,
                                <br></br>
                                Tamilnadu - 600 095.
                            </div>
                        </div>
                        <div>
                            <p>Contact Us</p>
                            <div>                    
                                <span>Mobile: </span> +91 9941924488<br></br>
                                <span>Landline: </span> 044 2376 1010<br></br>
                                <span>E-Mail: </span> <a style={{color:"white"}} href="mailto:sales@rajuchemicals.com">sales@rajuchemicals.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-suboutercontainer'>
                <div>
                    All rights reserved ® rajuchemical.com  | 
                    <span style={{cursor:"pointer"}} onClick={handleShow} > <u>Terms and conditions apply !.. </u> </span>
                </div>
                <div className='footer-sub-inoutercontainer'>
                    <img onClick={()=>handleimgClick("wp")} src={Whatsup} alt="whatsup"/>
                    <img onClick={()=>handleimgClick("lk")} src={Linkedin} alt="linkedin"/>
                    <img onClick={()=>handleimgClick("ins")}  src={Instagram} alt="instgram"/>              
                    
                </div>
            </div>
        </div>
        <br></br>
        {/* Modal for Terms and Conditions */}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Raju Chemicals - Terms and Conditions</h5>
                <p>
                    By accessing or using our services, you agree to the following terms:
                </p>
                <ul>
                    <li>All content provided by Raju Chemicals is for informational purposes only.</li>
                    <li>Raju Chemicals reserves the right to make changes to the content and services provided at any time.</li>
                    <li>Any reproduction or redistribution of content is strictly prohibited without prior written consent.</li>
                    <li>Users are responsible for complying with all applicable laws and regulations while using our services.</li>
                </ul>
                <h5>Copyright</h5>
                <p>
                    © {new Date().getFullYear()} Raju Chemicals. All rights reserved. Unauthorized use of the content and materials of this website, including reproduction, storage, or transmission, is prohibited without express written permission from Raju Chemicals.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
   )
}

export default Footer;