import React from 'react'
import "../../home/home.css";
import Icon1 from "../../../assets/homepg/icon1.png";
import Icon2 from "../../../assets/homepg/Icon2.png";
import Icon3 from "../../../assets/homepg/icon3.png";


export const  Features = (props) => {
  return(
    <div>
         <div className='home-icon-container'>
            <div className='home-icon-subcontainer'>
                <div><img src={Icon1} alt="vehicle-icon"/></div>
                <div><h3>On-Time Delivery</h3></div>
                <div>We ensure timely delivery, facilitating 
                smooth operations for our clients.</div>
            </div>
            <div  className='home-icon-subcontainer'>
                <div><img src={Icon2} alt="vehicle-icon"/></div>
                <div><h3>Exceptional Performance</h3></div>
                <div>Our products consistently exceed expectations,
                enhancing our clients' reputations</div>
            </div>
            <div  className='home-icon-subcontainer'>
                <div><img src={Icon3}  alt="vehicle-icon"/></div>
                <div><h3>Cost-Effectiveness</h3></div>
                <div>Our focus on cost-efficiency helps improve
                your financial outcomes and costless.</div>
            </div>
        </div>
    </div>
   )
  }

export default Features;