import React from 'react'
import "./contact.css";
import "../home/home.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ContactMan1 from "../../assets/contact/m1.jpg";
import ContactMan2 from "../../assets/contact/m2.jpg";
import CustomForm from '../forms/enquiryform';

const Contact = (props) => {

    const imgs = [
        { id: 1, src: ContactMan1},
        { id: 2, src: ContactMan2},        
    ];

    const settings = {
        dots: false,  // Shows navigation dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Show 4 slides on larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,        
    };   
  return(
    <div className='container'>
        <div className='contact-img-container'>
            <div className="contact-carousel-container">
                <Slider {...settings}>
                    {imgs.map((img) => (
                        <div key={img.id} className="contact-slide">
                            <img src={img.src} alt={`loadingman${img.id}`} className="contact-image" />                           
                        </div>
                    ))}
                </Slider>            
            </div>
            <br></br>
            <div className='contact-form-container'>
                    <h3 className='form-headings-text' ><span>Get In touch</span></h3>
                    <CustomForm/>
            </div>
            <br></br>
            {/* team container */}
            <div>
                <div className='home-team-container'>
                    <h1>Our Company Team</h1>
                    <p>Each member of our team is a qualified professional,
                    skilled in their respective fields.</p>                
                </div>        
            </div>
            {/* map section */}
            <br></br>
            <div className='contact-map-container'>
                <h3 className='form-headings-text' ><span>Location Raju Chemical Industries</span></h3>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14069.462516001438!2d80.1564309!3d13.0474102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526147e6940513%3A0x575d755e016136ed!2sRaju%20Chemical!5e1!3m2!1sen!2sin!4v1730800205482!5m2!1sen!2sin" style={{width:"auto",height:"auto",border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               
            </div>
            <br></br>
        </div>
    </div>
   )
  }

  
export default Contact;


    


