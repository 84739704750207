import React from 'react'
import "../../home/home.css";
import VissionImg from "../../../assets/homepg/vission1.png";
import SharedButton from '../button/button';

export const  Vision = (props) => {
    const handleClick = () => {
        window.location.href="/about";
    };
  return(
    <div>
       <div className='home-aboutus-container home-vision-container'>
                <div className='home-introimg-right'>
                    <img src={VissionImg} alt="man image"/>
                </div>
                <div className='home-about-right mission-right'>
                    <div></div>
                    <div>
                        <h1>
                        Our Vission
                        </h1>                
                    </div>
                    <div className='home-mission-content'>
                        <p>
                            <div><b>Innovate Continuously</b><span><b>:</b> Drive advancements in chemical manufacturing and distribution through cutting-edge technology and research, setting new benchmarks in the industry.</span></div>
                            <div><b>Lead with Excellence</b><span><b>:</b> Set the highest standards for product quality and service excellence, consistently surpassing client expectations and contributing to their success.</span></div>
                            <div><b>Foster Sustainable Growth</b><span><b>:</b> Achieve growth that is inclusive and beneficial to our clients, communities, and the environment, championing sustainable practices and corporate responsibility.</span></div>
                            <div><b>Expand Globally</b><span><b>:</b> Strengthen our presence across international markets, building partnerships and networks that enhance our global reach and impact.</span></div>
                            <div><b>Empower Our Team</b><span><b>:</b> Cultivate a dynamic and supportive work environment that motivates and develops our employees, recognizing their contributions as key to our success.</span></div>
                        </p>
                    </div>
                    
                    <div className='aboutus-btn-container'>
                        <SharedButton
                            content="Learn More"
                            onClick={handleClick}
                            btntype={"filled"}
                        />
                    </div>
                </div>                
            </div>
    </div>
   )
  }

export default Vision;