import React from 'react';
import './home.css';
import Intromanimg from "../../assets/homepg/homeman.png"
import SharedButton from '../shared/button/button';


import ProductCarousel from './productCarousel';
import RoProductCarousel from './roproductCarousel';
import Features from '../shared/comp/feature';
import Welcome from '../shared/comp/welcome';
import Mission from '../shared/comp/mission';

const Home = () => {
    

    const handleClick = (val) => {
        if(val === "Learn More"){
            window.location.href="/about";
        }        
    };
  return (
    <div className="container-fluid">
      <div className='home-introimg-container'>
        <div className='home-introimg-left'>
            <div></div>
            <div>
                <h1>
                    Assuring you of our 
                </h1>
                <h1>
                best Service
                </h1>
            </div>
            <div>
                <p>
                    We are the leading manufacturer and supplier of chemicals and related <br></br>products from chennai,tamilnadu(India).
                </p>
            </div>
            <div>
                <SharedButton
                    content="Learn More"
                    onClick={(val)=>handleClick(val)}
                    btntype={"outline"}
                />
            </div>
        </div>
        <div className='home-introimg-right'>
            <img src={Intromanimg} alt="man image"/>
        </div>
      </div>
      <br></br>
      <Features/>        
      <Welcome/>  
      {/* product needs to be included */}
      <div className='home-carosel-container'>
        <ul className="nav nav-tabs home-mob-ul" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="navheadings nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                    Commercial Chemicals
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button className=" navheadings nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> RO, ETP, STP Chemicals</button>
            </li>               
        </ul>
        <br></br>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <ProductCarousel/>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <RoProductCarousel/>
            </div>
        </div>
      </div>
      <br></br>
      <Mission/>   
    </div>
  );
};

export default Home;


