import React from 'react'
import "./about.css";
import SharedButton from '../shared/button/button';
import Welcome from '../shared/comp/welcome';
import MD from "../../assets/aboutpg/md.png"
import Office from "../../assets/aboutpg/office.JPG";
import Features from '../shared/comp/feature';
import Mission from '../shared/comp/mission';
import Vision from '../shared/comp/vision';
import TeamBanner from '../shared/comp/team';
import QualityAssurance from '../shared/comp/quality';
import Infrastruct from '../shared/infrastructure/infrastruct';

const About  = (props) => {
    const handleClick = () => {
        const element = document.getElementById('about-header');
        element.scrollIntoView({ behavior: 'smooth' });
        element.focus();
    };
  return(
    <div>
        <div className='container'>
            <div className='product-intro-container'>
                <div></div>
                <div>
                    <h2>About us</h2>
                </div>
                <div>
                    <p>We know how large objects will act, but things on a <br></br>
                    small scale just do not act that way.</p>
                </div>
                <div>
                    <SharedButton
                        content="Learn More"
                        onClick={handleClick}
                        btntype={"filled"}
                    />
                </div>
            </div>
            <br></br>
            <Welcome />
            <br></br>
            {/* MD raju chemicals */}
            <div id="about-header" className='about-md-container'>                
                <div className='about-md-right'>
                    <div></div>
                    <div className='about-md-heading'>
                        <h1>
                            MD of <br></br> Raju Chemical Industries
                        </h1>                
                    </div>
                    <div className='about-md-content'>
                        <p>
                        Raju Chemical Industries thrives under the leadership of Mr. R. Damodara Raju, 
                        the sole proprietor of the company. Known for his supportive nature, Mr. Raju 
                        offers comprehensive guidance to his employees, ensuring smooth operations across various
                        functions. His approach of delegating tasks not only enhances the efficiency of the team
                        but also boosts employee motivation. Thanks to his visionary leadership, the company has 
                        established a solid foundation in the industry and continues to achieve new milestones
                        as it progresses under his governance.
                        </p>
                    </div>          
                </div>

                <div className='about-md-left'>
                    <img className="mdimg" style={{opacity:1}} src={MD} alt="man image"/>
                </div>                
            </div>
            <br/>
            {/* icons container */}
            <Features/>
            {/* Notes of Raju Chemicals */}
            <div className='about-md-container'>                
                <div className='about-md-right'>
                    <div></div>
                    <div className='about-md-heading'>
                        <h1>
                            Notes of Raju Chemicals
                        </h1>                
                    </div>
                    <div className='about-md-content'>
                        <p>
                        As a truly global enterprise, we are dedicated to multi-dimensional growth
                         that is inclusive and beneficial to both our clients and the countries they operate in. 
                         This commitment is reinforced through our unwavering focus on quality.
                         We continually advance our manufacturing and distribution capabilities, 
                        ensuring alignment with industry standards and customer objectives.
                        </p>
                        <p>
                            Our operations are guided by three core values: <br/>
                            <ul>
                                <li>Respect for the Individual</li>
                                <li>Service to the Customer</li>
                                <li>Excellence in Pursuit of Our Goals</li>
                            </ul>
                        </p>
                        <p>These principles have established us as a trusted and respected organization globally.
                             We are devoted to delivering next-generation products and services,
                              leveraging modern technologies to meet the evolving needs of the industry.</p>
                    </div>          
                </div>

                <div className='about-md-left'>
                    <img className="officeimg" style={{opacity:1}} src={Office} alt="man image"/>
                </div>                
            </div>
            <br/>
            {/* infrastucture */}
            <div className='about-infra-container'>
                <h1>Our Infrastructure</h1>
                <Infrastruct rtlValue={false}/>
                <Infrastruct rtlValue={true}/>
            </div>
            {/* infrastucture */}
            <br/>
            <Mission/>
            <br/>     
        </div>
        <div>
            {/* looking */}
        </div>
        <br></br>
        <div className='container'>
            <Vision/>        
        </div>
        <br></br>
        <TeamBanner/>
        <br/>
        <div className='about-qa-container'>
             <h1>Quality Assurance</h1>
             <QualityAssurance/>
        </div>
    </div>
   )
  }

export default About;