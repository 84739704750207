import React from 'react'
// import "../../home/home.css";
import ReactCardCarousel from "react-card-carousel";
import "./quality.css";
import Card1 from "../../../assets/aboutpg/card4.png";
import Card2 from "../../../assets/aboutpg/card5.png";
import Card3 from "../../../assets/aboutpg/card6.png";

export const  QualityAssurance = (props) => {        
   
  return(
    <div className='qa-container'>
       <ReactCardCarousel autoplay={true} autoplay_speed={3500}>
          <div className='qa-card-container'>
            <img className="qa-card-img" src={Card1} alt="card"/>
          </div>
          <div className='qa-card-container'>
             <img className="qa-card-img" src={Card2} alt="card"/>
          </div>
          <div className='qa-card-container'>
             <img className="qa-card-img" src={Card3} alt="card"/>
          </div>
        </ReactCardCarousel>
    </div>
   )
  }

export default QualityAssurance;