import React, { useState, useEffect }  from 'react'
import RajuLogo from "../../../assets/common/bglogo.jpg";
import "./splash.css";



const SplashScreen = () => {
    const [animateLogo, setAnimateLogo] = useState(false);
  
    useEffect(() => {
      // Start the logo rotation animation
      const logoTimer = setTimeout(() => {
        setAnimateLogo(true);
      }, 1000); // Delay before starting the animation
  
      // Optionally, you can hide the logo after it finishes rotating
      const fadeOutTimer = setTimeout(() => {
        setAnimateLogo(false); // Hide the logo by removing the animation class
      }, 4000); // Total animation time
  
      // Clean up the timers
      return () => {
        clearTimeout(logoTimer);
        clearTimeout(fadeOutTimer);
      };
    }, []);
  
    return (
      <div className="splash-maincontainer">
        <div className="splash-container">
          <h1>Welcome to our business</h1>
          {/* Rotate the logo, then fade it out */}
          <img
            className={`raju-logo ${animateLogo ? 'rotate-fade' : ''}`}
            src={RajuLogo}
            alt="Raju Logo"
          />
        </div>
      </div>
    );
  };
  
  export default SplashScreen;