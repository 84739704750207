import React, { useEffect,useState } from 'react';
import './navheader.css';
import RajuLogo from "../../assets/common/bg-logo.jpg";
import { useLocation } from 'react-router-dom';

// Material-UI Icons
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';  // For Product
import InfoIcon from '@mui/icons-material/Info';  // About
import ContactMailIcon from '@mui/icons-material/ContactMail';  // Contact


const NavHeader = () => {
  const location = useLocation();
  const [activePath, setActivePath] = useState('/');

  useEffect(() => {
    // Update the active path whenever the location changes
    setActivePath(location.pathname);
  }, [location]);

  return (
    <header className="container-fluid">
      <nav className="navbar navbar-light">
        <div className="container-fluid navcontainers">
          {/* Left side rectangular icon */}
          <a className="navbar-brand" href="#">
            <img
              className='home-logo'
              src={RajuLogo}      
              alt="Raju Chemicals Logo"       
            />
          </a>
          <div className="menuitems">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <a className={`nav-link ${activePath === '/' ? 'activepage' : ''}`} href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activePath === '/product' ? 'activepage' : ''}`}  href="product">Product</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activePath === '/about' ? 'activepage' : ''}`}  href="about">About</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activePath === '/contact' ? 'activepage' : ''}`} href="contact">Contact</a>
                </li>
              </ul>
            </div>
          
          {/* Mobile toggle button */}
          <button
            className="navbar-toggler toggle-btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          
          {/* Off-canvas menu */}
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <div>
                <img
                  className='home-logo-canvas'
                  src={RajuLogo}      
                  alt="Raju Chemicals Logo"       
                />
              </div>
              <button
                type="button"
                className="btn-close closebtn"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                  <HomeIcon/><span>Home</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/product"><ShoppingCartIcon/><span>Product</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about"><InfoIcon/><span>About</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact"> 
                    <ContactMailIcon/>
                    <span>Contact</span>                    
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavHeader;
