import React from 'react'
import "../../home/home.css";
import MissionImg from "../../../assets/homepg/mission.png";

import SharedButton from '../button/button';

export const  Mission = (props) => {
    const handleClick = () => {
        window.location.href="/about";
    };
  return(
    <div>
       <div className='home-aboutus-container home-mission-container'>        
        <div className='home-about-right mission-right'>
            <div></div>
            <div>
                <h1>
                Our Mission
                </h1>                
            </div>
            <div className='home-mission-content'>
                <p>
                    <div><b>Delivering Superior Quality</b><span><b>:</b> Ensuring that all our products meet the highest standards of purity, reactivity, and safety to enhance the efficiency and success of our clients' operations.</span></div>
                    <div><b>Fostering Innovation</b><span><b>:</b> Continuously advancing our manufacturing and distribution processes through modern technologies and research to meet the evolving needs of the industry.</span></div>
                    <div><b>Maintaining Reliability</b><span><b>:</b> Providing on-time delivery and exceptional performance to support smooth and effective operations for our clients.</span></div>
                    <div><b>Promoting Sustainable Growth</b><span><b>:</b> Driving multi-dimensional development that benefits our clients, our communities, and the environment through inclusive and responsible practices.</span></div>
                    <div><b>Upholding Core Values</b><span><b>:</b> Adhering to our principles of Respect for the Individual, Service to the Customer, and Excellence in all our endeavors to build trust and deliver exceptional value.</span></div>
                </p>
            </div>
            
            <div className='aboutus-btn-container'>
                <SharedButton
                    content="Learn More"
                    onClick={handleClick}
                    btntype={"filled"}
                />
            </div>
        </div>
        <div className='home-introimg-right'>
            <img src={MissionImg} alt="man image"/>
        </div>
      </div>
    </div>
   )
  }

export default Mission;