import React from 'react';
import "./supplier.css";
import Sup1 from "../../../assets/aboutpg/suppliers/abg.png";
import Sup2 from "../../../assets/aboutpg/suppliers/asl.png";
import Sup3 from "../../../assets/aboutpg/suppliers/dfp.png";
import Sup4 from "../../../assets/aboutpg/suppliers/ensign.jpg";
import Sup5 from "../../../assets/aboutpg/suppliers/merck.jpg";
import Sup6 from "../../../assets/aboutpg/suppliers/pc.jpg";
import Sup7 from "../../../assets/aboutpg/suppliers/reach.jpg";
import Sup8 from "../../../assets/aboutpg/suppliers/sanmar.jpg";
import Sup9 from "../../../assets/aboutpg/suppliers/tanfac.png";
import Sup10 from "../../../assets/aboutpg/suppliers/tata.png";
import Sup11 from "../../../assets/aboutpg/suppliers/tgv.png";
import Sup12 from "../../../assets/aboutpg/suppliers/ccal.jpg";

const Suppliers = (props) => {
  return(
    <div class="supplier-container">
      <div class="scrolling-images">
            <img src={Sup1} alt="supplier1" />
            <img src={Sup2} alt="supplier2" />
            <img src={Sup3} alt="supplier3" />
            <img src={Sup4} alt="supplier4" />
            <img src={Sup5} alt="supplier5" />
            <img src={Sup6} alt="supplier6" />
            <img src={Sup7} alt="supplier7" />
            <img src={Sup8} alt="supplier8" />
            <img src={Sup9} alt="supplier9" />
            <img src={Sup10} alt="supplier10" />
            <img src={Sup11} alt="supplier11" style={{width:"100px"}} />
            <img src={Sup12} alt="supplier12" />
            {/* duplicates */}
            <img src={Sup1} alt="supplier1" />
            <img src={Sup2} alt="supplier2" />
            <img src={Sup3} alt="supplier3" />
            <img src={Sup4} alt="supplier4" />
            <img src={Sup5} alt="supplier5" />
            <img src={Sup6} alt="supplier6" />
            <img src={Sup7} alt="supplier7" />
            <img src={Sup8} alt="supplier8" />
            <img src={Sup9} alt="supplier9" />
            <img src={Sup10} alt="supplier10" />
            <img src={Sup11} alt="supplier11" style={{width:"100px"}}/>
            <img src={Sup12} alt="supplier12" />
        </div>
    </div>
   )
  }

export default Suppliers;